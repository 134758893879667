@import "~ngx-toastr/toastr.css";

/* * {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "DM Sans", sans-serif !important;
  background-repeat: no-repeat;
} */

html,
body {
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "DM Sans", sans-serif !important;
  background-repeat: no-repeat;
}

.sec-1 {
  background: linear-gradient(289.69deg,
      #b39ddb -28.85%,
      #dccbfb 14.35%,
      #ffffff 78.69%);
  padding-top: 80px;
}

.sec-1-div {
  position: relative;
  padding-left: 7%;
  padding-top: 6%;
  padding-bottom: 3%;
}

.sec-1-top-text {
  font-size: 60px;
  font-weight: 700;
  margin-right: 40%;
  line-height: 60px;
  color: #6768ab;
  letter-spacing: -2px;
}

.sec-1-bottom-text {
  font-size: 29px;
  font-weight: 500;
  margin-right: 45%;
  line-height: 36px;
  color: #6768ab;
  letter-spacing: -2px;
  margin-top: 10%;
}

.sec-1-img-div {
  position: absolute;
  right: 0;
  bottom: 0;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

.sec-2-right-div {
  color: #6768ab;
  margin-top: 35%;
  width: 57%;
  margin-left: 23%;
}

.img-2 {
  width: 100%;
}

.sec-4-right-div {
  color: #6768ab;
  margin-top: 44%;
  width: 57%;
  margin-left: 23%;
}

.sec-4-right-top-text {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 46px;
}

.sec-4-right-bottom-text {
  font-size: 22px;
  line-height: 24px;
  padding-top: 15px;
}

.sec-2-right-top-text {
  font-size: 53px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 46px;
}

.sec-2-right-bottom-text {
  font-size: 22px;
  line-height: 24px;
  padding-top: 15px;
}

.sec-3-img {
  margin-top: -100px;
  width: 100%;
  max-width: 100%;
}

.sec-3-top-text {
  color: #6768ab;
  font-size: 41px;
  line-height: 45px;
  letter-spacing: 0px;
  font-weight: 700;
  padding: 255px 0px 10px 59px;
}

.sec-3-bottom-text {
  color: #6768ab;
  font-size: 17px;
  line-height: 24px;
  padding-left: 63px;
}

/* STYLING OF LEFT-RIGHT-BUTTON  START*/
/* STYLING OF LEFT-RIGHT-BUTTON  START*/
/* STYLING OF LEFT-RIGHT-BUTTON  START*/

.on-off-btn {
  width: 40px;
  border: 2px solid #b39ddb;
  background: white;
  height: 24px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 4px;
  transition: all 0.5s;
}

.on-off-dot {
  width: 14px;
  height: 14px;
  border: 2px solid #b39ddb;
  background: white;
  border-radius: 10px;
  transition: all 0.5s;
}

.toggle-btnn-active {
  border: 2px solid #7e57c2;
  background: #7e57c2;
}

.active-on-off-btn {
  background: #b39ddb;
  transition: all 0.5s;
  padding-left: 18px;
}

/* STYLING OF LEFT-RIGHT-BUTTON  END*/

/* navbar css */
/* navbar css */
/* navbar css */

nav {

  backdrop-filter: blur(8px);
  width: 100%;
  background: linear-gradient(289.69deg,
      #b39ddb -28.85%,
      #dccbfb 4.35%,
      #ffffff 78.69%);
  /* padding: 15px 10% 15px 100px;
  justify-content:space-around; */
  padding: 1% 5% 1% 7%;
  position: fixed;
  top: 0;
  z-index: 10;
}

.logo {
  width: 35%;
}

.logo-on-mbl {
  display: none;
}

.login-txt {
  cursor: pointer;
  color: #6768ab;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.05em;
  padding: 10px 40px;
  border-radius: 50px;
  min-width: 140px;
  text-align: center;
}

.try-rectangle {
  border: 1px solid #d1c4e9;
  color: white;
  padding: 16px 10px 16px 10px;
  /* min-width: 178px; */
  width: 270px;
  text-align: center;
  transition: all 0.5s;
  color: #d1c4e9 !important;
}

.try-rectangle span {
  visibility: none;
}

.try-rectangle:hover {
  background: #9747ff;
  border-color: #9747ff;
  color: white !important;
  font-weight: 700;
  transition: all 0.5s;
  padding-right: 40px;
}

.try-rectangle:hover>span {
  display: inline;
  font-weight: 900;
}

.user {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: center;
  color: #6768AB;
}

.login-txt:hover {
  color: white;
  background-color: #523F90;
}

.burger-menu {
  display: none;
}

/* CSS OF DROP-DOWN */
.drop-down-container {
  width: 100%;
  height: 270px;
  background: #523F90;
  position: fixed;
  display: none;
  top: -500px;
  padding-top: 70px;
  left: 0px;
  transition: all 0.5s;
}

.drop-down-container p {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  margin-bottom: 20px;
  color: white;
}

.active-drop-down {
  top: 60px;
  transition: all 0.5s;
}

@media only screen and (max-width: 768px) {
  .final {
    margin-left: 58.33333333% !important;
  }

  nav {
    padding: 15px 10%;
    justify-content: space-between !important;
  }

  .logo-on-mbl,
  .burger-menu,
  .drop-down-container {
    display: block !important;
  }

  .logo-on-pc,
  .login-txt,
  .try-rectangle,
  .user {
    display: none !important;
  }
}

/* navbar css ends */
/* navbar css ends */

/* .mycontainer {
  background-color: #311b92;
} */

.main-header {
  font-family: DM Sans;
  font-size: 112px;
  font-weight: 700;
  line-height: 120px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #e5e2e9;
  margin-top: 160px;
}

.bgi-1 {
  background-image: url(/assets/images/bg-1.png);
  background-position: top right;
}

.headerr {
  font-family: DM Sans;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #6768ab;
}

.para {
  font-family: DM Sans;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #6768ab;
}

.s-para {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #b39ddb;
  width: 200px;
  margin-top: -70px;
  margin-left: 200px;
}

.learn {
  display: none;
}

.bg-2 {
  background-image: url(/assets/images/bg-2.png);
  background-position: top right;
  background-size: 76% 100%;
}

.bg-3 {
  background-image: url(/assets/images/bg-3.png);
  background-position: top right;
  background-size: 100%;
}

.bg-4 {
  background-image: url(/assets/images/bg-4.png);
  background-position: top right;
}

.bg-5 {
  background-image: url(/assets/images/bg-5.png);
  background-position: top left;
  background-size: 95%;
}

.bg-6 {
  background-image: url(/assets/images/bg-6.png);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: -120px;
}

.trail {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #b48bff;
  cursor: pointer;
}

.trail:hover {
  color: white;
}

.cardd {
  border-radius: 32px;
  padding: 10px 20px;
  margin-top: 90px;
  font-weight: 400;
  box-shadow: 4px 4px 16px #d1c4e9;
  background: #ffffff;
}

.cardd:hover {
  background: #7E57C2;
  box-shadow: none;
}

.cardd:hover>.plan-name-text,
.cardd:hover>.plan-details,
.cardd:hover>.plan-price {
  color: #ffffff;
}

.card:hover>.btnn {
  color: #523F90;
  background-color: #ffffff !important;
}

.plan-name-text {
  font-size: 56px;
  font-weight: 700 !important;
  line-height: 57px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #6768ab;
}

.plan-details {
  font-family: DM Sans;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #6768ab;
}

.plan-price {
  font-family: DM Sans;
  font-size: 26px;
  line-height: 40px;
  letter-spacing: -0.05em;
  text-align: center;
  color: #6768ab;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top-btn-height {
  height: 42px;
}

.top-btn {
  border: 2px solid #523f90;
  padding: 8px 15px;
  background: #ffffff;
  border-radius: 24px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #523f90;
}

.top-btn:hover {
  border: 2px solid #ffffff;
  background: #523F90;
  color: #ffffff;
}

.top-btn1 {
  border: 2px solid #b39ddb;
  padding: 8px;
  background: #ffffff;
  border-radius: 24px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #b39ddb;
}

.top-btn1:hover {
  border: 2px solid #ffffff;
  background: #523F90;
  color: #ffffff;
}

.btn-margin-bottom {
  margin-bottom: 45px;
}

.btnn {
  border: none;
  border-radius: 24px;
  font-family: DM Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: center;
  width: 180px;
  padding: 12px 0px;
  background: #523f90;
  color: white;
}

.btnn:hover {
  color: #523F90;
  background-color: #ffffff;
}

@media (max-width: 992px) {
  .cardd {
    border: 1px solid #d1c4e9;
  }
}

@media (max-width: 1098px) {
  .plan-name-text {
    font-size: 45px !important;
  }
}

@media (max-width: 991px) {
  .para {
    margin-top: 30px;
  }
}

@media (max-width: 1245px) {
  .sec-4 img {
    width: 100%;
  }
}

.contact-btn:hover {
  background-color: white;
  color: #311b92;
  border: 1px solid #fff;
  /* box-shadow: 0px 10px 20px #6831c9; */
}

.app-btn:hover {
  background-color: white;
  color: #311b92;
  box-shadow: 0px 10px 20px #6831c9;
  border-color: white;
}

.plan {
  margin-top: 120px;
}

.sec-5 {
  padding-bottom: 120px;
  padding-top: 100px;
}

.contact-btn {
  background-color: #403D39;
  border: 2px solid #403D39;
  color: #FAFAFA;
}

.app-btn {
  background-color: transparent;
  border: 2px solid #ffffff;
  color: #b39ddb;
}

footer {
  background-color: #252422;
  padding-top: 100px;
  padding-bottom: 200px;
}

.hrr {
  width: 90%;
  margin: auto;
  border-bottom: 1px solid #7e57c2;
  margin-top: 120px;
}

.mx-100 {
  margin-left: 100px;
  margin-right: 100px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mt--55 {
  margin-top: -55px;
}

.ml--50 {
  margin-left: -50px;
}

.ml--30 {
  margin-left: -30px;
}

.pt-250 {
  padding-top: 250px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-115 {
  margin-top: 115px;
}

.pt-150 {
  padding-top: 150px;
}

.pl-100 {
  padding-left: 100px !important;
}

.pt-170 {
  padding-top: 170px;
}

.para-float {
  margin-left: 315px;
  margin-top: -55px;
}

.z-index-5 {
  z-index: 5;
}

.carousell {
  display: none;
}

.carousel-indicators [data-bs-target] {
  background-color: #523F90;
}

@media (max-width: 992px) {

  /* img{
      margin-left: 0px !important;
      margin-right: 0px !important;
  } */
  .avatar img {
    margin-top: 40px !important;
    margin-left: 0px !important;
  }

  .s-para {
    display: none;
  }

  .para-float {
    margin: 0px;
  }

  .slider {
    display: none;
  }

  .carousell {
    display: block !important;
  }

  .carousel-indicators {
    margin-bottom: -50px !important;
  }

  .carousel-indicators button {
    width: 50px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}

@media (max-width: 480px) {
  .toast-container {
    width: 95% !important;
  }

  .toast-container .ngx-toastr {
    width: 100% !important;
  }

  /* .home-container div{ */
  .home-container div:not(.home-container .carousel div) {
    background-image: none !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  nav {
    padding-right: 24px !important;
    padding-left: 24px !important;
  }

  /* .inner-container {
    padding-left: 24px;
      padding-right: 24px;
  } */

  .main-header {
    font-size: 56px;
    line-height: 64px;
    margin-top: 100px;
  }

  .col-rev {
    flex-direction: column-reverse !important;
  }

  .headerr {
    font-size: 40px;
    line-height: 48px;
  }

  .learn {
    display: block;
  }

  .para {
    font-family: DM Sans;
    font-size: 24px;
    line-height: 32px;
  }

  .disappear {
    display: none;
  }

  .bgi-1 {
    display: none;
  }

  .s-para {
    display: none;
  }

  .para-float {
    margin: 0;
  }

  .avatar {
    margin-top: 90px;
  }

  .pt-250 {
    padding-top: 0px;
  }

  .bg-3 {
    margin-top: 136px;
  }

  .mt-130 {
    margin-top: 0px;
  }

  .mt-115 {
    margin-top: 0px;
  }

  img {
    margin: 0 !important;
  }

  .pl {
    padding-left: 0px;
  }

  .pt-150 {
    padding-top: 0px;
  }

  .pl-100 {
    padding-left: 0px !important;
  }

  .hrr {
    width: 40%;
    margin: auto !important;
    margin-top: 160px !important;
  }

  /* .home-container .col-12,
  .container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
  } */

  footer {
    padding-left: 24px;
    padding-right: 24px;
  }

  footer .headerr {
    text-align: left !important;
  }
}

/* Login page css */
/* Login page css */
/* Login page css */

.login {
  background-color: #fff;
  position: fixed;
  min-height: 96vh;
  width: 50%;
  top: 2%;
  right: 0;
  z-index: 15;
  transition-duration: 0.5s;
  transform: translateX(100%);
  padding-left: 40px;
  padding-right: 40px;
  overflow-y: auto;
  max-height: 96vh;
}

.back-btn {
  top: 40px;
  left: 40px;
  width: 20px;
  position: absolute;
}

.login-translate-0 {
  transform: translateX(0%);
  transition-duration: 0.5s;
}

.login .login-container {
  width: 56%;
  margin: auto;
  padding-top: 40px;
  position: relative;
}

.login-header {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #6768AB;
  margin-bottom: 40px;
  text-align: center;
}

.option {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #6768AB;
  margin-top: 35px;
  padding: 0px 3px;
}

.login form {
  margin-top: 35px;
}

.login form input:focus {
  outline: none;
}

.login form .input-div {
  background-color: #f8f8f8;
  border-radius: 4px 4px 0px 0px;
  padding-left: 10px;
  margin-bottom: 40px;
  border-bottom: 2px solid #6768AB;
}

.login form div label {
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #999898;
}

.login form div input {
  background-color: transparent;
  padding: 0px 0px 5px;
  border: none;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #999898;
}

.forgot {
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  color: #6768AB;
  cursor: pointer;
}

.remember {
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  color: #b39ddb;
}

.login-btn {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  width: 100%;
  letter-spacing: -0.05em;
  padding: 12px 0px;
  background-color: #523F90;
  color: #d1c4e9;
  border-radius: 24px;
  border: none;
  margin-top: 50px;
  border: 2px solid #523F90;
}

.login-btn:hover {
  background-color: white;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.3);
  color: #523F90;
  border: 2px solid #523F90;
}

.login-container form div .register {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #999898;
}

.login-container form div .sign-upp {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  color: #6768AB;
  text-decoration: underline;
}

.back-img {
  display: none;
}

@media (max-width: 992px) {
  .login {
    width: 60%;
    padding: 10px px !important;
  }

  .login .login-container {
    width: 100%;
  }

  .back-btn {
    position: static !important;
    margin-top: 25px;
  }
}

@media (max-width: 768px) {
  .login {
    width: 100%;
    padding: 0px 40px !important;
    min-height: 103vh;
    top: 0;
  }

  .login form .input-div {
    margin-bottom: 15px;
  }

  .login-header {
    text-align: left !important;
  }

  .login .login-container {
    width: 70%;
    padding-top: 10px;
  }

  .back-btn {
    width: 40px;
    position: static !important;
    margin-top: 25px;
  }

  .back-img {
    display: block;
  }

  .cross-img {
    display: none;
  }
}

@media (max-width: 576px) {
  .login {
    width: 100%;
    padding: 10px 20px !important;
  }

  .login .login-container {
    width: 100%;
  }
}

/* Sign up page */
/* Sign up page */

.sign-up {
  background-color: #fff;
  position: fixed;
  min-height: 96vh;
  width: 50%;
  top: 2%;
  right: 0;
  z-index: 15;
  transform: translateX(100%);
  transition-duration: 0.5s;
  padding: 0px 40px;
  overflow-y: auto;
  max-height: 96vh;
}

.sign-up-translate-0 {
  transform: translateX(0%);
  transition-duration: 0.5s;
}

.secure {
  font-family: DM Sans;
  font-size: 35px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #6768AB;
}

.sign-up-container {
  width: 100%;
  margin: auto;
  padding-top: 30px;
  position: relative;
}

.sign-up form .input-div {
  position: relative;
  margin-bottom: 20px;
}

.sign-up form .input-div .pass-disc {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #979797;
}

.sign-up form input {
  padding: 8px 10px;
  border-radius: 8px;
  border: 1px solid #6768AB;
}

.sign-up form input:focus {
  outline: none;
}

.sign-up form label {
  position: absolute;
  background-color: white;
  top: -12px;
  left: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #4e4e4e;
}

.sign-up-header {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: #6768AB;
  margin-bottom: 30px;
}

.sign-up-btn {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  width: 50%;
  letter-spacing: -0.05em;
  padding: 12px 0px;
  background-color: #523F90;
  color: #d1c4e9;
  border-radius: 24px;
  border: none;
  margin-top: 20px;
  border: 2px solid #523F90;
}

.sign-up-btn:hover {
  background-color: white;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.3);
  color: #523F90;
  border: 2px solid #523F90;
}

@media (max-width: 992px) {
  .sign-up {
    width: 60%;
  }

  .secure {
    padding-left: 0px !important;
    text-align: center;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .secure {
    display: none;
  }

  .sign-up {
    width: 100%;
    padding: 10px 40px;
    min-height: 103vh;
    top: 0;
  }

  .sign-up-container {
    padding-top: 0;
    width: 70%;
  }

  .sign-up-header {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .sign-up {
    padding: 10px 20px;
  }

  .sign-up-container {
    padding-top: 0;
    width: 100%;
  }
}

/* free trail css */
/* free trail css */
/* free trail css */

.trial-header {
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.05em;
  color: #dccbfb;
  margin-bottom: 24px;
}

.trial-container {
  padding-top: 60px;
  width: 90%;
  margin: auto;
}

.trial-container .login-button {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: #fff;
  background: #9747ff;
  padding: 10px 0px;
  width: 312px;
  border-radius: 24px;
  border: none;
  margin-bottom: 12px;
}

.trial-container .hidden-buttons {
  visibility: hidden;
  padding-top: 40px;
  padding-bottom: 50px;
}

.trial-disc {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #dccbfb;
}

.trial-container .note {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: #b39ddb;
  margin-bottom: 120px;
}

.trial-container .note span {
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.05em;
}

.trial-container .login-container {
  border-radius: 32px;
  padding: 40px 15px;
}

.trial-container form {
  margin-top: 35px;
}

.trial-container form input:focus {
  outline: none;
}

.trial-container form .input-div {
  background-color: #f8f8f8;
  border-radius: 4px 4px 0px 0px;
  padding-left: 10px;
  margin-bottom: 30px;
  border-bottom: 2px solid #b39ddb;
}

.trial-container form div label {
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #999898;
}

.trial-container form div input {
  background-color: transparent;
  padding: 0px 0px 5px;
  border: none;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: #999898;
}

@media (min-width: 991px) {
  .trial-container .login-container {
    width: 73%;
    margin: auto;
  }
}

@media (max-width: 480px) {
  .trial-container {
    width: 100%;
  }

  .trial-container .login-container {
    display: none;
  }

  .trial-container .hidden-buttons .google-img {
    width: 100% !important;
    height: 60px;
  }

  .trial-container .hidden-buttons .login-button {
    width: 97% !important;
  }

  .trial-container .hidden-buttons {
    visibility: visible;
    padding-top: 75px;
    padding-bottom: 100px;
  }
}

/* forgot password page css */
/* forgot password page css */

.forgot-password {
  background-color: #fff;
  position: fixed;
  min-height: 96vh;
  width: 50%;
  top: 2%;
  right: 0;
  z-index: 9992;
  transform: translateX(100%);
  transition-duration: 0.5s;
  background: #210e78;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 96vh;
}

.forgot-password-translate-0 {
  transform: translateX(0%);
  transition-duration: 0.5s;
}

.forgot-password-container {
  width: 100%;
  margin: auto;
  /* padding-top: 40px; */
  position: relative;
}

.forgot-password-content {
  margin-left: 80px;
  padding-top: 35px;
}

.bg-dog {
  background-image: url(/assets/images/dog.png);
  background-size: 100% 80%;
  height: 100%;
  background-position: bottom;
}

.forgot-heading {
  font-size: 48px;
  color: #f8f8f8;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.05em;
  text-align: left;
}

.dog-img img {
  /* width: 90%; */
  margin-top: 109px;
  display: none;
}

.small-heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #b39ddb;
  /* margin-left: 20%; */
}

.forgot-password input[type="email"] {
  color: #b39ddb;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border: 2px solid #b39ddb;
  border-radius: 5px;
  background: transparent;
  /* margin-left: 20%; */
  padding: 16px 0px 16px 16px;
  outline: none;
}

.btnnn {
  color: #311b92;
  background: white;
  padding: 12px 63px 12px 62px;

  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: left;
  width: 190px;
  border-radius: 50px;
  margin-left: 20%;
}

.cancel-btn {
  color: #b39ddb;
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.05em;
  text-align: left;
  width: 300px;
  text-align: center;
}

.DOGGY {
  display: none;
}

@media only screen and (min-width: 787px) {
  .cancel-btn {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .forgot-password {
    width: 100%;
    padding: 0px 24px;
    min-height: 103vh;
    top: 0;
  }

  .cross {
    display: none;
  }

  .forgot-password-content {
    padding: 0;
  }

  .forgot-heading {
    font-size: 40px;
    margin-top: 20px !important;
  }

  .bg-dog {
    display: none;
  }

  .DOGGY {
    display: block;
  }

  .forgot-password-content {
    margin-left: 0px;
  }

  .forgot-heading,
  .small-heading,
  .button,
  input[type="email"] {
    margin-left: 0px;
  }

  .btn-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: baseline;
  }

  .btnnn {
    margin-left: 0px;
  }

  .dog-img img {
    margin-top: 10px;
    width: 100%;
    margin-left: 30px;
  }
}

/* reset link page */
/* reset link page */
/* reset link page */

.reset-linkk {
  background-color: #fff;
  position: fixed;
  min-height: 96vh;
  width: 50%;
  top: 2%;
  right: 0;
  z-index: 15;
  transform: translateX(100%);
  transition-duration: 0.5s;
  background: #210e78;
  overflow-y: auto;
  max-height: 96vh;
}

.reset-linkk-translate-0 {
  transform: translateX(0%);
  transition-duration: 0.5s;
}

.cross {
  margin-left: 6%;
  margin-top: 25px;
}

.cross img {
  cursor: pointer;
}

.bg-color {
  background: #210e78;
  padding-bottom: 20px;
  width: 100%;
}

.reset-link-content {
  margin-left: 80px;
}

.rese-link-heading {
  color: #f8f8f8;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.05em;
  text-align: left;
}

.reset-time {
  color: #b39ddb;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.05em;
  text-align: left;
}

.home-btn {
  padding: 12px 5px 12px 5px;
  border: 4px solid #b39ddb;
  color: #b39ddb;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: center;
  width: 185px;
  border-radius: 50px;
  margin-top: 100px;
  margin-bottom: 80px;
  width: 184px;
  cursor: pointer;
}

.on-mbl {
  display: none;
}

@media only screen and (max-width: 768px) {
  .reset-linkk {
    width: 100%;
    min-height: 103vh;
    top: 0;
  }

  .rese-link-heading {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.05em;
  }

  .reset-link-heading {
    font-size: 40px;
    margin-top: 20px !important;
  }

  .cross {
    display: none;
  }

  .container {
    padding-left: 50px;
  }

  .bg-color {
    padding: 1% 5%;
  }

  .home-btn {
    margin: auto;
    margin-top: 120px;
    margin-bottom: 100px;
  }

  .on-pc {
    display: none;
  }

  .on-mbl {
    display: block;
  }

  .reset-link-content {
    margin-left: 0px;
  }
}

/* contact-us page */
/* contact-us page */

.contact-us {
  background-color: #311b92;
  position: fixed;
  min-height: 96vh;
  width: 60%;
  top: 2%;
  right: 0;
  z-index: 15;
  transform: translateX(100%);
  transition-duration: 0.5s;
  padding: 0px 40px;
  overflow-y: auto;
  max-height: 96vh;
}

.bgc-purp {
  background-color: #311b92;
}

.contact-us-translate-0 {
  transform: translateX(0%);
  transition-duration: 0.5s;
}

.contact-us-header {
  font-family: DM Sans;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.05em;
  margin-top: 80px;
  color: #f8f8f8;
}

.contact-info {
  padding-top: 75px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #b39ddb;
}

.contact-form input,
textarea {
  width: 100%;
  background-color: transparent;
  border: 2px solid #b39ddb;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  padding: 16px;
  margin-bottom: 20px;
}

.contact-form textarea {
  height: 100px;
  color: #fff;
}

.bgc-transparent {
  background-color: transparent;
}

.contact-send-btn {
  margin-top: 40px;
}

@media (max-width: 768px) {
  .contact-us {
    width: 100%;
    padding: 10px 20px;
    min-height: 103vh;
    top: 0;
  }

  .contact-us .container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    max-width: 100% !important;
  }

  .contact-us-container {
    padding-top: 0;
    width: 70%;
  }

  .contact-us-header {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .contact-send-btn {
    text-align: center;
  }
}

@media (max-width: 576px) {
  .contact-us {
    padding: 10px 20px;
  }

  .contact-us-container {
    padding-top: 0;
    width: 100%;
  }
}

/* trial-subscription page css */
/* trial-subscription page css */
/* trial-subscription page css */

.mainn {
  background-color: #2c1789;
  background-image: url(/assets/images/trailbg.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 975px 850px;
}

.headerrr {
  background: rgba(49, 27, 146, 0.5);
  backdrop-filter: blur(12px);
}

.samantha {
  color: #d1c4e9;
  text-align: end;
  padding: 32px 0px;
}

.bg-fix {
  position: absolute;
  filter: blur(8px);
  /* width: 100%; */
  bottom: 0;
  /* left: 120px; */
  z-index: 2;
  transform: translateX(55%);
}

.enjoy {
  font-size: 56px;
  line-height: 64px;
  letter-spacing: -0.05em;
  color: #dccbfb;
  margin-top: 45px;
}

.sent {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #d1c4e9;
  margin-top: 24px;
}

.info {
  color: #fff;
  text-decoration: underline;
}

.order {
  font-size: 32px;
  color: #d1c4e9;
  line-height: 24px;
  margin-top: 37px;
}

.order-summary {
  font-size: 32px;
  color: #d1c4e9;
  line-height: 24px;
  margin-top: 37px;
}

.detail {
  /* color: white; */
  margin-top: 44px;
}

.view {
  font-size: 16px;
  color: #b48bff !important;
  line-height: 24px;
  text-decoration: none;
}

.view:hover {
  text-shadow: 0px 3px 6px #b48bff;
  color: #b48bff;
  transition: 0.5s;
}

.number {
  font-size: 16px;
  line-height: 24px;
  color: #d1c4e9;
}

.numberr {
  margin-top: 22px;
}

.cpk {
  margin-top: 22px;
}

.homepagebtn {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #b39ddb;
  border: 4px solid #b39ddb;
  border-radius: 50px;
  padding: 12px 28px 12px 29px;
  background-color: #2c1789;
  margin-top: 50px;
  margin-bottom: 100px;
}

.homepagebtn:hover {
  background-color: #fff;
  border: 4px solid #fff;
  color: #2c1789;
  transition: 1.5s;
}

@media (max-width: 480px) {
  .mainn {
    background-image: none;
  }

  .bg-fix {
    display: none;
  }

  .padd-left-0 {
    padding-left: 0px !important;
  }

  .padd-rit-0 {
    padding-right: 0px !important;
  }

  .align-right {
    text-align: right;
  }

  .thirtydaystrial {
    padding: 0px 24px;
  }

  .enjoy {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
  }

  .sent {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 75px;
  }

  .detail {
    display: none;
  }

  .home-page-buttton {
    text-align: center;
  }
}

/* get app page css */
/* get app page css */
/* get app page css */

.get-the-app-container {
  padding-top: 10%;
}

.main-get {
  background-color: #4527a0;
}

.main-get-app {
  padding-top: 12%;
}

.headderrr {
  color: #6768AB;
  font-size: 56px;
  font-weight: 600;
  letter-spacing: -3px;
}

.headerr-1 {
  color: #6768AB;
}

.got {
  padding-top: 115px;
}

.got:hover {
  color: #6768AB;
}

.android {
  font-size: 25px;
  font-weight: 400;
  margin-bottom: 0rem;
}

.apple {
  font-size: 30px;
  /* text-decoration: underline; */
  font-weight: 600;
}

.ios {
  padding-top: 40px;
}

.ios:hover {
  color: #6768AB;
}

.vector {
  margin-left: 10px;
  padding-bottom: 15px;
}

.tablet {
  padding-top: 65px;
}

.navbar-right {
  color: #6768AB;
  cursor: pointer;
}

.user-text,
.logout-text {
  cursor: pointer;
}

.notte {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.ipad {
  font-size: 24px;
  font-weight: 500;
}

.line {
  /* Increase this as per requirement */
  border-bottom-style: solid;
  border-bottom-width: 3.1px;
  width: fit-content;
}

.bg-app {
  background-image: url(/assets/images/apps.png);
  position: relative;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% 115%;
  height: 900px;
}

.phon11 {
  position: absolute;
  top: 72%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-notte {
  display: none;
}

@media (max-width: 480px) {
  .bg-app {
    background-image: none !important;
    height: auto;
    text-align: center;
    margin-top: 70px;
  }

  .phon11 {
    position: static;
    width: 70%;
    transform: translate(0%, 0%);
  }

  .tablet {
    display: none;
  }

  .mobile-notte {
    display: block;
  }

  .notte {
    font-size: 56px;
    font-weight: 300;
    line-height: 56px;
    margin-bottom: 20px;
    margin-top: 80px;
    color: #b39ddb;
  }

  .ipad {
    font-size: 24px;
    line-height: 32px;
    color: #b39ddb;
    margin-bottom: 80px;
  }

  .get-the-app-container {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

/* order details section css */
/* order details section css */
/* order details section css */

.bg-img-of-order-sec {
  background-color: white !important;
  background: url(/assets/images/white-cloud-bg.png);
  background-repeat: no-repeat;
  background-position: right;
  padding: 1px;
}

.order-section-navbar {
  width: 100%;
  padding: 20px 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hello-user-order-sec {
  color: #4527a0;

  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: center;
}

.logo-on-mbl {
  display: none;
}

.back-arrow-img {
  height: 40px;
  display: flex;
  align-items: flex-end;
}

.order-detailss {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 90px;
  padding-right: 2%;
}

.icons-of-detail-sec {
  width: 30%;
  color: #4527a0;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
}

.back-arrow {
  width: 30%;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #4527a0;
}

.payment-details {
  margin: 50px 0px 100px 15%;
}

td {
  color: #6768AB;
  padding-bottom: 16px;
}

.thin-texts {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.05em;
  text-align: right;
  padding-right: 110px;
}

.mini-text {
  color: #311b92;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: left;
}

.bold-texts {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.05em;
  text-align: left;
}

@media only screen and (max-width: 1200px) {
  .back-arrow {
    width: 50%;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.05em;
    text-align: left;
    color: #4527a0;
  }
}

@media only screen and (max-width: 786px) {
  .bg-img-of-order-sec {
    background: white;
  }

  .payment-details {
    margin-left: 10%;
    width: 90%;
  }

  .thin-texts {
    padding-right: 50px;
  }

  .back-arrow {
    width: 50%;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.05em;
    text-align: left;
    color: #4527a0;
  }

  .icons-of-detail-sec {
    width: 45%;
    color: #4527a0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
  }

  .logo-on-mbl {
    display: block;
  }

  .logo-on-pc {
    display: none;
  }
}

@media only screen and (max-width: 650px) {
  .order-detailss {
    flex-wrap: wrap;
  }

  .order-detail-heading {
    width: 100%;
    text-align: center;
    margin-top: 50px;
  }

  .back-arrow {
    width: 100%;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.05em;
    text-align: left;
    color: #4527a0;
    flex-wrap: wrap;
  }

  .icons-of-detail-sec {
    width: 100%;
    color: #4527a0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 550px) {
  .order-detailss {
    flex-wrap: wrap;
  }

  .payment-details {
    margin-left: 5%;
  }

  .thin-texts,
  .bold-texts {
    font-size: 20px;
  }

  .order-detail-heading {
    width: 100%;
    text-align: center;
    margin-top: 50px;
  }

  .back-arrow-img {
    width: 100%;
    margin-left: 8%;
  }

  .back-arrow {
    width: 100%;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.05em;
    text-align: left;
    color: #4527a0;
    flex-wrap: wrap;
  }

  .icons-of-detail-sec {
    width: 100%;
    color: #4527a0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    margin-top: 50px;
  }
}

@media (max-width: 480px) {

  .thin-texts,
  .bold-texts {
    font-size: 15px;
  }

  .thin-texts {
    padding-right: 10px;
  }
}

/* password-reset.html page css */
/* password-reset.html page css */
/* password-reset.html page css */
/* password-reset.html page css */

.bg-body-container {
  background: #311b92;
  padding: 1px 8%;
}

.brainy-nav-bar {
  padding: 30px 0%;
}

.main-reset-container {
  padding: 1px 0px;
  display: flex;
  flex-wrap: nowrap;
}

.reset-pass-heading {
  color: #d1c4e9;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.05em;
  text-align: left;
  margin-left: 13%;
}

.left-column-password {
  width: 50%;
}

.surety-heading {
  color: #b39ddb;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: left;
  margin-left: 13%;
  opacity: 0.5;
}

.password-set-rules {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #b39ddb;
  margin-left: 7.5%;
}

.pass-feilds {
  width: 80%;
  padding-top: 50px;
  margin-bottom: 100px;
}

.pass-feilds input {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border: 2px solid #b39ddb;
  border-radius: 5px;
  background: transparent;
  margin-left: 20%;
  padding: 16px 16px 16px 16px;
  outline: none;
  margin-left: 15%;
  width: 400px;
}

.pass-feilds ::placeholder,
input {
  color: #b39ddb;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  /* font-style: italic; */
}

.sub-btn {
  color: #311b92;
  background: white;
  padding: 12px 30px 12px 30px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: center;
  width: 190px;
  border-radius: 50px;
  margin: auto;
  /* margin-left: 33%; */
}

.sub-btn:hover {
  background-color: #6831c9;
  color: white;
  box-shadow: 0px 10px 20px #6831c9;
}

.right-column-background img {
  opacity: 0.5;
  width: 100%;
}

.right-column-background {
  padding-top: 90px;
}

@media only screen and (max-width: 768px) {
  .right-column-background {
    display: none;
  }

  .brainy-nav-bar {
    display: none;
  }

  .left-column-password {
    width: 100%;
  }

  .pass-feilds {
    width: 100%;
  }

  .pass-feilds input {
    margin-left: 0px;
  }

  .password-set-rules {
    margin-right: 0px;
  }

  .reset-pass-heading,
  .password-set-rules {
    margin-left: 0px !important;
  }

  .password-set-rules {
    width: 300px;
  }
}

@media only screen and (max-width: 500px) {
  .main-reset-container {
    justify-content: center;
  }

  .surety-heading {
    margin-left: 30px !important;
  }

  .reset-pass-heading {
    margin-top: 54px;
    margin-bottom: 24px !important;
  }

  .bg-body-container {
    padding: 1px 24px;
  }

  .pass-feilds input {
    width: 100%;
  }
}

/* payment option page css */
/* payment option page css */
/* payment option page css */

.main-bg-containerr {
  background-color: #ffffff;
  padding-top: 80px;
  min-height: 105vh;
}

.payment-sec-body {
  display: flex;
  justify-content: space-between;
  padding: 80px 7% 0px;
  color: #6768AB;
}

.cancel-payment-mbl {
  display: none;
}

.payment-sec-body input {
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 2px solid #9747FF;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  color: #6768AB;
}

.payment-sec-body ::placeholder {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #7e57c2;
}

.payment-selection {
  min-height: 50vh;
  width: 34%;
}

.pay-details-heading {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.05em;
  text-align: left;
}

.payment-selection-box {
  display: flex;
  justify-content: flex-start;
  margin-top: 35px;
  margin-bottom: 40px;
}

.payment-boxess {
  width: 184px;
  padding: 8px 0px;
  border-radius: 5px;
  border: 2px solid #5E35B1;
  color: #B39DDB;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: center;
  margin-right: 20px;
  cursor: pointer;
}

.name-on-card {
  color: #b39ddb;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #d1c4e9;
  padding-top: 18px;
}

.input-type-name input {
  width: 100%;
  padding-bottom: 4px;
}

.master-visa-card {
  position: relative;
  left: 110%;
  bottom: 40px;
  width: 100px;
}

.three-input-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 5px;
}

.three-input-container ::placeholder {
  color: #B39DDB !important;
}

.input-type-name ::placeholder {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  color: white;
  text-align: left;
}

.three-input-container input {
  border: none;
  outline: none;
  background: transparent;
  border-bottom: 2px solid #b39ddb;
  width: 100%;
  padding-bottom: 4px;
  width: 18%;
}

.expiry-field {
  min-width: 80px;
}

.input-card-number {
  border-bottom: 2px solid #b39ddb;
  display: flex;
  align-items: baseline;
  width: 45%;
  min-width: 184px;
}

.card-number-field {
  border: none !important;
  width: 90% !important;
}

.toggle-btn-box {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #b39ddb;
  width: 80%;
  margin-top: 55px;
  margin-bottom: 65px;
}

.address-heading {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.05em;
  color: #6768AB;
  margin-bottom: 45px;
}

.address-heading input {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #b39ddb;
}

.row-address {
  width: 100%;
  margin-bottom: 35px;
}

.row-address input {
  width: 45%;
  padding-bottom: 4px;
}

.address-section {
  margin-bottom: 96px;
}

.address-section ::placeholder {
  color: #B39DDB;
}

.address-section input {
  border-bottom: 2px solid #B39DDB;
}

.enterprise-plan-box {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #f8f8f8;
  /* padding: 40px 90px 90px 56px; */
  background: rgba(151, 71, 255, 0.6);
  /* width: 70%; */
  border-radius: 30px;
  height: 475px;
  width: 525px;
  z-index: 2;
}

.user-employ-location {
  font-size: 32px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #d1c4e9;
}

.para-about-plan {
  color: #d1c4e9;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.plan-box {
  display: flex;
  justify-content: flex-end;
  width: 65%;
  align-items: flex-start;
}

.make-payment {
  color: #311b92;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  width: 208px;
  padding: 12px 0px;
  background: white;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
}

.make-payment:hover {
  background-color: #6831c9;
  color: white;
  box-shadow: 0px 10px 20px #6831c9;
}

.cancell {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #d1c4e9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.flex-box-column {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
}

.cancel-payment {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 85px;
}

@media only screen and (max-width: 1300px) {

  .enterprise-plan-box,
  .cancel-payment {
    width: 70%;
  }
}

@media only screen and (max-width: 1100px) {

  .enterprise-plan-box,
  .cancel-payment {
    width: 80%;
  }
}

@media only screen and (max-width: 1050px) {
  .payment-sec-body {
    padding: 1px 3%;
  }

  .payment-section-navbar {
    padding: 20px 3%;
  }

  .enterprise-plan-box {
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.05em;
    text-align: left;
    color: #f8f8f8;
    padding: 45px 60px 45px 56px;
    background: #6b32cd;
    width: 62%;
    border-radius: 30px;
    width: 85%;
  }

  .payment-selection {
    width: 40%;
  }

  .master-visa-card {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .payment-section-navbar {
    padding: 15px 6%;
    align-items: center;
  }

  .payment-sec-body {
    padding-left: 24px;
    padding-right: 6%;
  }

  .logo-on-mbl,
  .burger-menu,
  .cancel-payment-mbl {
    display: block;
  }

  .logo-on-pc,
  .hello-user,
  .plan-box,
  .master-visa-card {
    display: none;
  }

  .payment-sec-body,
  .payment-selection {
    width: 100%;
  }

  .address-section {
    margin-bottom: 70px;
  }

  .address-section input,
  .input-type-name {
    margin-bottom: 30px;
  }

  .on-mbl-full {
    width: 100% !important;
  }

  .cancel-payment-mbl {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 100px;
  }

  .make-payment {
    margin-bottom: 24px;
  }
}

@media (max-width: 480px) {
  .toggle-btn-box {
    width: 100%;
  }

  .address-heading {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.05em;
  }
}

@media only screen and (max-width: 450px) {
  .payment-section-navbar {
    padding-left: 3%;
    padding-right: 3%;
  }

  .payment-boxess {
    width: 144px;
  }
}

@media (max-width: 380px) {
  .cvv-field {
    margin-top: 35px;
  }
}

/* reset-successfully.html page css */
/* reset-successfully.html page css */
/* reset-successfully.html page css */

.main-background-cntner {
  background: #311b92;
}

.left-column-reset-section {
  background: url("/assets/images/reset-section-bg-2.png");
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: left 15%;
}

.right-column-reset-section-bg {
  background: url("/assets/images/reset-section-bg-1.png");
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: right -80px;
}

.padding-of-reset-section {
  padding: 1px 8% 1px 100px;
}

.brainy-nav-bar {
  padding: 30px 0%;
}

.login-box-pc ::placeholder,
input {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #999898;
  text-align: left;
  outline: none;
  border: none;
}

.input-for-password,
.input-for-email {
  border-bottom: 2px solid #b39ddb;
  padding: 4px 10px;
  border-radius: 5px 5px 0px 0px;
}

.login-box-pc input {
  width: 90%;
  background: transparent;
}

.legend-heading {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #999898;
}

.reset-successfully-heading {
  color: #dccbfb;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.05em;
  text-align: left;
  margin-bottom: 150px;
}

.buttons-for-mbl {
  padding-left: 20%;
  display: none;
}

.goto-login-btn {
  color: #311b92;
  background: white;
  padding: 12px 10px 12px 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: center;
  width: 190px;
  border-radius: 50px;
  margin-top: 100px;
}

.back-to-home-btn {
  color: #dccbfb;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  width: 200px;
}

.login-box-pc {
  width: 440px;
  min-height: 400px;
  background: white;
  margin-bottom: 200px;
  border-radius: 20px;
  padding: 48px 46px;
}

@media only screen and (max-width: 768px) {
  .buttons-for-mbl {
    display: block;
    padding-bottom: 300px;
    width: 100%;
    /* margin: auto; */
    padding-left: 0px;
    text-align: center;
  }

  .buttons-for-mbl div {
    margin: auto;
  }

  .reset-successfully-heading {
    margin-top: 100px;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.05em;
  }

  .left-column-reset-section,
  .right-column-reset-section-bg {
    background: transparent;
  }

  .brainy-nav-bar {
    display: none;
  }

  .padding-of-reset-section {
    padding-left: 0px;
    padding-right: 0px;
  }

  .login-box-pc {
    display: none;
  }

  .main-background-cntner {
    padding: 0%;
  }

  .reset-successfully-footer {
    display: none;
  }

  .last-reset-section {
    background: url("/assets/images/rese-section-bg-3.png");
    background-size: 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
    padding: 1px 24px;
  }
}

/* ceckout trail page css */
/* ceckout trail page css */
/* ceckout trail page css */
/* ceckout trail page css */

.maiinn {
  background-color: #ffffff;
  padding-top: 100px;
}

.relative {
  position: relative;
}

.enterprise {
  background: rgba(151, 71, 255, 0.6);
  backdrop-filter: blur(2px);
  border-radius: 32px;
  /* padding: 52px 80px 57px 56px; */
  height: 475px;
  width: 525px;
  /* margin: 100px auto; */
  z-index: 2;
}

.enterprise-shadow {
  padding: 48px;
}

.frontBox-shadow {
  left: 34px;
  top: 38px;
  background: #5E35B1;
  filter: blur(32px);
  border-radius: 32px;
  height: 475px;
  width: 525px;
  z-index: 1;
}

.absolute {
  position: absolute;
}

.text-container {
  z-index: 3;
}

.w-full {
  width: 100%;
}

.text-white {
  color: #fff;
}

.plann {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.05em;
  color: #f8f8f8;
}

.days {
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 11px;
}

.colors {
  font-size: 32px;
  line-height: 24px;
  color: #d1c4e9;
  margin-top: 34px;
  font-weight: 500;
}

.employ {
  margin-top: 16px;
}

.paraa {
  font-size: 18px;
  line-height: 24px;
  color: #d1c4e9;
  margin-top: 46px;
  font-weight: 500;
}

.order {
  color: #d1c4e9;
}

.order .ord-summ {
  font-size: 42px;
  font-weight: 600;
  color: #6768AB;
}

.order span {
  line-height: 56px;
  letter-spacing: -0.05em;
}

.order .table1 tr td {
  font-size: 19px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: -0.05em;
  color: #6768AB;
  text-align: right !important;
  padding-right: 10px;
  width: fit-content;
  white-space: nowrap;
}

.left-spacing {
  padding-left: 40px;
}

.padding_0 {
  padding-left: 0px;
}

.order ul {
  margin-top: 25px
}

.order li {
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #6768AB;
}

.order .table1 {
  margin-bottom: 0%;
  margin-top: 56px;
  padding-left: 24px;
}

.order_free_plan {
  margin-left: 5px;
  color: #6768AB;
}

.order .table2 {
  margin-left: 23px;
  margin-top: 10px;
  width: 90%;
}

.first_td {
  font-size: 18px;
  font-weight: 700;
  color: #6768AB;
}

.last_td {
  font-size: 14px;
  font-weight: 500;
  padding-left: 8px;
  color: #6768AB;
}

.pmt-btn {
  text-align: end !important;
  padding-top: 70px;
}

.line {
  margin-top: 32px;
}

.billed {
  font-size: 16px;
  line-height: 24px;
  margin-top: 39px;
}

hr {
  color: #b39ddb;
  margin: 0%;
  margin-top: 32px;
}

.buttton {
  border: none;
  font-size: 18px;
  text-align: center;
  color: white;
  width: 288px;
  padding: 12px 0px;
  text-align: center;
  color: #311b92;
  margin-bottom: 75px;
  border-radius: 24px;
  letter-spacing: -5%;
  margin-top: 91px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
}

.buttton:hover {
  background-color: #6831c9;
  color: white;
  box-shadow: 0px 10px 20px #6831c9;
}

.total {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.05em;
  margin-top: 32px;
}

.order_btn {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  padding: 8px 50px 8px 50px;

}

.pmt_btn {
  /* width: inherit; */
  padding: 12px 40px 12px 40px !important;
}

.prev-page-btn {
  /* display: none; */
  background-color: transparent !important;
  color: #6768AB !important;
}

.payment_btn {
  color: #ffffff;
  background-color: #6768AB;
  border-radius: 24px;

}

.payment_btn:hover {
  box-shadow: 0px 8px 8px rgba(10, 0, 52, 0.25);
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}

.arroww {
  text-align: center;
}

.arroww img {
  cursor: pointer;
}

@media (max-width: 480px) {

  /* .arroww {
    display: none;
  } */
  .buttton-container {
    text-align: center;
  }

  .checkout-trail-container {
    padding: 0px 24px !important;
  }

  .order ul {
    margin-top: 24px;
  }

  .plann {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.05em;
  }

  .colors {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    margin-top: 24px;
  }

  .paraa {
    font-size: 14px;
    margin-top: 16px;
  }

  .paraa br {
    display: none;
  }

  .order .ord-summ {
    font-size: 40px;
  }

  .order span {
    line-height: 48px;
  }

  .billed {
    margin-top: 0px;
    text-align: justify;
  }

  .enterprise {
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 40px;
    border-radius: 8px;
    box-shadow: inset 20px 20px 10px 0px rgba(0, 0, 200, .5), 0 0 0 0 rgba(0, 0, 150, .5), rgba(0, 0.25, 0, 0.25) 30px 0px 30px 0px, rgba(0, 0, 0, 0.1) 0px 79px 40px 0px, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }

  .prev-page-btn {
    display: inline;
    margin-bottom: 40px;
  }

  .t-right {
    text-align: right;
  }

  .buttton {
    margin-bottom: 20px;
    margin-top: 70px;
  }
}

/* four in one page
  .enterprise plan
  payment details
  transaction history
  change password */

.user-profile-change-password-container {
  background: #ffffff;
  padding-top: 12%;
}

.common-div-padding {
  padding: 38px 100px 0px 12px;
}

.user-profile-bg {
  /* min-height: 100vh; */
  height: auto;
}

.sec-options {
  display: flex;
  margin-bottom: 12px;
  cursor: pointer;
}

.mid-body-of-user-profile {
  display: flex;
}

.selected-section {
  color: #ffffff;
  font-weight: 500;
}

.row-padding {
  margin-top: 15px;
  padding-top: 2%;
}

.user-profile-sections-div {
  color: #7e57c2;
  text-align: left;
  background: #300065;
  border-bottom-right-radius: 230px 330px;
}

.user-profile-sections {
  /* width: 32%; */
  /* color: #7e57c2;
  text-align: left;
  background: #300065;
  border-bottom-right-radius: 230px 330px; */
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  /* margin-top: 25px; */
  min-height: 105vh;
}

.menu-padding {
  padding: 46px 0px 0px 58px;
}

.bg-fixed-img {
  position: absolute;
  right: 0;
  bottom: 0;
}

/* password-change-section  start*/
.user-profile-main-heading {
  color: #6768AB;
  font-size: 45px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.05em;
  text-align: left;
}

.pass-change-fields {
  width: 87%;
}

.change-password-container {
  width: 100%;
  padding: 27px 75px 1px 75px;
  margin-top: 25px;
}

.pass-fields-containers {
  display: flex;
  margin-bottom: 40px;
  align-items: baseline;
  justify-content: end;
}

.pass-fields-lebal {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: right;
  color: #6768AB;
  /* margin-right: 25px; */
}

.pass-fields-containers input,
.employyes_field input {
  border: 2px solid #b39ddb;
  outline: none;
  color: #b39ddb;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  background: none;
  height: 52px;
  width: 392px;
  border-radius: 5px;
  padding-left: 16px;
}

.employyes_field input {
  width: auto;
}

.employyes_field input::-webkit-inner-spin-button {
  display: none;
}

.pass-fields-containers ::placeholder {
  color: #b39ddb;
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
  line-height: 24px;
  letter-spacing: 0em;
}

.small-line {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #6768AB;
  /* padding-top: 20px; */
}

.password_icon img {
  position: absolute;
  top: 8px;
  right: 15px;
  cursor: pointer;
}

.password_icon_login img {
  position: absolute;
  top: -8px;
  right: 15px;
  cursor: pointer;
}

.hide {
  display: none;
}

.resett-pass {
  background: white;
  color: #6768AB;
  border: 2px solid #6768AB !important;
  width: 180px;
  padding: 12px 0px;
  border-radius: 24px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: center;
  border: none;
}

.resett-pass:hover {
  box-shadow: 0px 10px 20px #6831c9;
  color: white;
  background: #6831c9;
}

/*password-secction-END */
.other-sections {
  display: none;
  width: 100%;
  padding-left: 75px;
}

/* PAYMENT SECTION START */

.payment-detailss {
  margin-bottom: 60px;
}

.credit-debits {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #6768AB;
  margin-bottom: 47px;
}

.visa-container-flex {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #6768AB;
  width: 450px;
  margin-bottom: 105px;
}

.down-arrow-img {
  margin-left: 100px;
  cursor: pointer;
}

.playpal-id-container {
  display: flex;
  align-items: center;
  gap: 50px;
}

.playpal-id-container input {
  border: 2px solid #6768AB;
  outline: none;
  width: 318px;
  background: none;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 5px;
  height: 56px;
  color: #6768AB;
  padding-left: 16px;
}

.playpal-id-container ::placeholder {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #6768AB !important;
}

.paypal-id-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #6768AB;
}

.edit-box {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: left;
  color: #6768AB;
  cursor: pointer;
}

.direction-inverse {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  gap: 50px;
}

/* payment section end */
/* payment section end */
/* payment section end */

/*TRANSACTION JYSTORY START  */
/*TRANSACTION JYSTORY START  */
/*TRANSACTION JYSTORY START  */

.trans {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -0.05em;
  color: white;
}

.weight {
  font-weight: 400;
}

.enterprisee {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 60px;
}

.pllan {
  display: flex;
  justify-content: space-between;
  width: 386px;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.05em;
  color: #6768AB;
}

.Orrder {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 34px;
  color: #6768AB;
  width: 270px;
  margin-left: 15px;
}

.userss {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: #6768AB;
  margin-top: 8px;
}

.purchasee {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #6768AB;
  margin-top: 39px;
  width: 85%;
}

.bbutton {
  border: 4px solid #b39ddb;
  color: #b39ddb;
  background-color: #311b92;
  width: 184px;
  padding: 12px 0px;
  border-radius: 30px;
  margin: 33px 0px 328px;
  letter-spacing: -0.05em;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

/* transaction hystory END */
/* transaction hystory END */
/* transaction hystory END */

/* My subscription start */
/* My subscription start */
/* My subscription start */

.validation-date {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #6768AB;
  margin-left: 37%;
}

.paragraph-flexbox {
  /* width: 930px; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 65px;
}

.plan-details-contianer {
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: left;
  display: flex;
  width: 910px;
  color: #6768AB;
  justify-content: space-between;
}

.size-20px-headings {
  font-size: 20px;
  margin-bottom: 28px;
  font-weight: 500;
}

.size-22px-headings {
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: 600;
  padding-top: 12px;
  color: #6768AB
}

.size-18px-lines {
  font-size: 16px;
  line-height: 25px;
  color: #6768AB
}

.size-16px-lines {
  font-size: 16px;
  /* line-height: 33px; */
  font-weight: 500;
  color: #6768AB
}

.plan_table {
  font-weight: 500;

}

.plans-date-and-type {
  display: flex;
  width: 40%;
  justify-content: space-between;
}

.margin-top {
  margin-top: 36px;
}

.font-weight {
  font-weight: 400;
}

.ch-nge-pass-input {
  width: 100% !important;
  border: 2px solid #6768AB;
  border-radius: 4px;
  background-color: transparent;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  padding: 12px 10px;
  color: #6768AB;
}

.ch-nge-pass-input::placeholder {
  font-style: italic;
  color: #6768AB;
  font-size: 16px;
}

.btnnnnnn {
  text-align: end;
}

.black-border {
  position: relative;
}

.up-arrow-drop {
  display: none;
}

/* my Subscription end */

@media only screen and (max-width: 1400px) {

  .paragraph-flexbox,
  .plan-details-contianer {
    width: 860px;
  }
}

@media only screen and (max-width: 1300px) {
  .change-password-container {
    padding: 1px 40px;
  }

  .pass-change-fields {
    width: 100%;
  }

  .paragraph-flexbox,
  .plan-details-contianer {
    width: 800px;
  }
}

@media only screen and (max-width: 1024px) {
  .Orrder {
    margin-left: 0px;
  }

  .purchasee {
    width: 100%;
    font-weight: 500;
  }

}

@media only screen and (max-width: 1300px) {

  .paragraph-flexbox,
  .plan-details-contianer {
    width: 800px;
  }

  .plans-date-and-type {
    width: 45%;
  }
}

@media (max-width: 1250px) {
  .pllan {
    width: 400px;
  }

  .enterprisee {
    width: 690px;
  }
}

@media only screen and (max-width: 1100px) {
  /* .user-profile-bg {
    padding: 1px 4%;
  } */

  .user-profile-sections {
    width: 30%;
  }

  .pass-change-fields {
    width: 100%;
  }

  .change-password-container {
    padding: 0px;
  }

  .paragraph-flexbox,
  .plan-details-contianer {
    width: 600px;
  }

  .plans-date-and-type {
    width: 60%;
  }
}

@media only screen and (max-width: 1000px) {

  .paragraph-flexbox,
  .plan-details-contianer {
    width: 90%;
  }

  .plans-date-and-type {
    width: 400px;
  }

  .paragraph-flexbox,
  .plan-details-contianer,
  .plans-date-and-type {
    flex-wrap: wrap;
  }

  .enterprise-plan-heading {
    margin-bottom: 10px;
    width: 400px;
  }

  .pllan {
    width: 350px;
  }

  .enterprisee {
    width: 600px;
  }
}

@media only screen and (max-width: 786px) {

  .logo-on-mbl,
  .burger-menu {
    display: block;
  }

  .up-arrow-drop {
    display: block;
    position: absolute;
    top: 40px;
    right: 30px;
  }

  .padding-left-35px {
    padding-left: 35px;
  }

  .padding-left-50px {
    padding-left: 50px;
  }

  .logo-on-pc,
  .hello-userr {
    display: none;
  }

  .change-password-container {
    width: 100%;
    padding: 0px;
  }

  .other-sections {
    padding-left: 0px;
  }

  .user-profile-bg {
    padding: 1px 2%;
  }

  .pass-fields-containers {
    justify-content: center;
    flex-wrap: nowrap;
  }

  .pass-fields-containers input {
    width: 250px;
  }

  .pass-fields-lebal {
    margin-bottom: 0px;
    /* width: 100%; */
  }

  .pass-fields-lebal {
    margin-right: 0px;
    font-size: 22px;
  }

  .visa-container-flex {
    margin-bottom: 57px;
  }

  .playpal-id-container {
    margin-bottom: 186px;
  }

  .enterprisee {
    width: 100%;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 670px) {

  .padding-left-50px,
  .padding-left-35px {
    padding-left: 0px;
  }

  .pass-fields-lebal {
    /* width: 80%; */
    text-align: left;
  }

  .playpal-id-container input {
    position: absolute;
    top: 45px;
    width: 100%;
    right: 0px;
  }

  .playpal-id-container {
    position: relative;
  }

  .direction-inverse {
    justify-content: flex-end;
  }

  .playpal-id-container,
  .visa-container-flex {
    width: 500px;
  }
}

@media only screen and (max-width: 560px) {

  .visa-container-flex,
  .playpal-id-container {
    width: 100%;
  }

  .playpal-id-container {
    align-items: baseline;
  }

  .direction-inverse {
    flex-direction: column-reverse;
  }

  .month {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.05em;
    width: 70%;
  }

  .playpal-id-container input {
    position: absolute;
    top: 45px;
    width: 100%;
    right: 0px;
  }

  .playpal-id-container {
    position: relative;
  }

  .direction-inverse {
    align-items: flex-end;
  }

  /* .playpal-id-container,.visa-container-flex{
     width:  500px;
  } */

  .size-18px-lines {
    font-size: 16px;
  }

  .zargar {
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .pllan {
    width: 250px;
    flex-wrap: wrap;
  }

  .black-border {
    border: 1px solid #d1c4e9;
    border-radius: 10px;
    padding: 16px;
    margin-top: 60px;
    margin-bottom: 108px;
  }

  .bbutton-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .bbutton {
    margin: 50px auto;
  }

  .enterprisee-container {
    width: 100%;
  }

  .purchasee {
    flex-wrap: wrap;
    margin-top: 0px;
    width: 100%;
  }

  .userss {
    display: none;
  }
}

@media only screen and (max-width: 500px) {

  .pass-fields-lebal {
    justify-content: flex-start;
    margin-bottom: 16px;
    width: 100%;
  }

  .margin-bottom {
    margin-bottom: 48px;
  }

  .mid-body-of-user-profile {
    justify-content: center;
  }

  .plans-date-and-type {
    width: 100%;
  }

  .paragraph-flexbox,
  .plan-details-contianer {
    width: 100%;
  }

  .pass-change-fields {
    width: 100%;
  }

  .pllan {
    flex-wrap: wrap;
    width: 100%;
  }

  .pass-fields-containers {
    justify-content: left;
    flex-wrap: wrap;
  }

  .credit-debits span {
    display: none;
  }

  .playpal-id-container input {
    position: absolute;
    top: 45px;
    width: 100%;
    right: 0px;
  }

  .playpal-id-container {
    position: relative;
  }

  .direction-inverse {
    align-items: flex-end;
  }

  /* .playpal-id-container,.visa-container-flex{
     width:  360px;
  } */

  .user-profile-main-heading {
    font-size: 42px;
  }
}

@media only screen and (max-width:375) {
  /* .bg-fixed-img{
    bottom: -268px !important;
  } */
}

@media (max-width: 768px) {
  .menu-padding {
    padding: 25px 0px 0px 0px;

  }

  .user-profile-sections-div {
    padding: 0px 0px 0px 5px;
  }

  /* .pass-fields-lebal {
    justify-content: flex-start;
    margin-bottom: 16px;
    width: 100%;
    text-align: left;
  } */
}

@media only screen and (max-width: 500px) {
  .pass-fields-containers input {
    width: 370px;
  }

  .menu-padding {
    font-size: 12px !important;
    line-height: 14px;
    padding: 25px 0px 0px 0px;

  }

  .user-profile-bg {
    padding: 0px 24px;
  }

  .playpal-id-container {
    justify-content: space-between;
  }

  .btnnnnnn {
    text-align: center;
    margin-bottom: 100px;
  }

}

@media only screen and (max-width: 440px) {
  .pass-fields-containers input {
    width: 360px;
  }

  /* .user-profile-sections {
    width: 100% !important;
  } */

  .common-div-padding {
    padding: 25px 0px 0px 0px;
  }

  .enterprisee-container {
    width: 100%;
  }

  .pass-fields-containers {
    justify-content: left;
    flex-wrap: wrap;
  }

  .enterprise-plan-heading {
    margin-top: 32px;
  }

  .plan-details-contianer div {
    width: 100%;
  }

  .margin-top {
    position: relative;
    bottom: 83px;
    left: 138px;
  }
}

@media only screen and (max-width: 400px) {
  .pass-fields-containers input {
    width: 300px;
  }

  .paypal-id-text {
    width: 110px;
  }
}

@media (max-width: 320px) {
  .menu-padding {
    font-size: 10px !important;
  }
}

.page-remover {
  cursor: pointer !important;
}

.toast-bootstrap-compatibility-fix {
  opacity: 1;
}

.invalid-feedback {
  color: #b39ddb !important;
  /* position:absolute !important;
  top:48px !important;
  left:12px !important; */
}

.toast-container .ngx-toastr {
  background: #7e57c2 !important;
}

@media print {
  .noprint {
    display: none;
  }
}

.cpointer {
  cursor: pointer;
}

.footer-heading {
  color: #CCC5B9;
  text-align: center;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -2.8px;
}

@media (max-width: 1024px) {
  .sec-2 .img-2 {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .sec-1-div .row {
    flex-direction: column;
  }

  .sec-1-div {
    padding-bottom: 28%;
    padding-left: 10%;
  }

  .sec-1-img-div {
    display: flex;
    justify-content: end;
    width: 81%;
  }

  .sec-1-img-div img {
    width: 60%;
  }

  .sec-1-top-text,
  .sec-1-bottom-text {
    margin-right: 10%;
  }

  .sec-2 .row {
    flex-direction: column-reverse;
  }

  .sec-2 .img-1 {
    width: 80%;
  }

  .sec-2 .img-2 {
    width: 70%;
  }

  .sec-2 .img-2 {
    width: 100%;
  }

  .sec-2-right-div {
    display: flex;
    flex-direction: column;
    margin: 10%;
    width: 65%;
  }

  .sec-3-top-text {
    padding: 10% 10% 5%;
  }

  .sec-3-img {
    margin: 0;
    width: 100%;
  }

  .sec-3-bottom-text {
    padding: 0 10%;
  }

  .sec-4 .row {
    display: flex;
    flex-direction: column-reverse;
  }

  .sec-4-right-div {
    margin: 0 10%;
    width: 80%;
  }

  .sec-4 img {
    width: 80%;
  }

  .carousel-item {
    gap: 24px;
  }

  .footer-heading {
    font-size: 42px;
    line-height: 48px;
  }

  footer {
    padding: 50px 0 100px;
  }

  .footer-btn-container {
    padding: 0 10%;
  }
}

.sec-2-right-top {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .sec-3 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 639px) {
  .sec-1-top-text {
    font-size: 44px;
    line-height: 50px;
  }

  .sec-2 .img-1 {
    width: 40%;
  }

  .sec-2 img-2 {
    width: 80%;
  }

  .sec-2-right-div,
  .sec-4-right-div {
    padding-left: 10%;
  }

  .sec-5 {
    padding-left: 10%;
    padding-right: 10%;
  }

  .footer-heading {
    font-size: 30px;
    line-height: 35px;
  }

  .footer-heading br {
    display: none;
  }

  .btnn {
    width: 100%;
  }
}

@media(max-width:639px) {
  .user-profile-change-password-container {
    padding-top: 70px;
  }

  .user-profile-bg {
    padding: 0;
  }

  .user-profile-container {
    display: flex;
    flex-direction: column;
  }

  .user-profile-sections-div,
  .user-profile-rgtColumn {
    width: 100%;
  }

  .user-profile-sections-div {
    border-radius: 0;
  }

  .user-profile-rgtColumn {
    padding: 0 30px;
  }

  .user-profile-sections {
    min-height: auto;
    width: 100%;
  }

  .menu-padding {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 10px;
  }

  .menu-padding div {
    text-align: center;
    padding: 0 10px;
  }

  .sec-options {
    margin-bottom: 0;
  }
}

@media(max-width:768px) {
  .plan-container {
    margin-bottom: 100px;
  }

  .order .table1 tr td {
    font-size: 16px;
    line-height: 25px;
  }

}

@media(max-width:375px) {
  .google_logo_div {
    padding: 4px 5px 7px 5px !important;
  }
}

@media(max-width:639px) {

  .enterprise,
  .frontBox-shadow {
    height: 100%;
    width: 100%;
  }

  .order .table1 {
    overflow-x: auto;
  }

  .order_btn {
    padding: 10px;
  }

}


/*============================*/
/*===== new ui design ========*/
/*=============================*/
.banner_section {
  background-image: url(/assets/images/new-home/hero.png);
  height: 122vh;
  background-size: cover;
  background-position: right top;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5vh;
}

.banner_content h1 {
  color: #FAFAFA;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
}

.banner_content p {
  color: #CCC5B9;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

/*=== fact section ===*/

.fast_accurte {
  position: relative;
  padding: 80px 0;
  background-image: url(/assets/images/new-home/fast-bg.png);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 40%;
}

.section_content h2 {
  color: #252422;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}

.section_content p {
  color: #252422;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}


/*== real time ===*/
.real_time {
  background-image: url(/assets/images/new-home/real-time-bg.png);
  background-position: 104% 66%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 60%;
  margin-top: -365px;
}

/*=== ensure ===*/
section.ensure {
  background-image: url(assets/images/new-home/ensure-bg.png);
  background-size: 40%;
  background-position: 0% 5%;
  padding: 80px 0;
  background-repeat: no-repeat;
}

/*== find the way ===*/
section.find_the_right {
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;
}

.packages_card {
  border-radius: 32px;
  background: #fff;
  box-shadow: 4px 4px 16px 0px rgba(153, 152, 152, 0.50);
  padding: 64px 32px 32px;
}

.packages_card_detail h4 {
  color: #403D39;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.6px;
}

.packages_card_detail p {
  color: #403D39;
  text-align: start;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.packages_card_detail ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.packages_card_detail ul li {
  color: #403D39;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -1.2px;
}

.packages_card_detail ul li:last-child {
  font-weight: 700;
}

.btn.btn-outline-primary,
.btn.btn-primary {
  border-radius: 8px;
  border: 1px solid #403D39;
  color: #403D39;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  width: 100%;
  padding: 11px 10px;
  outline: none;
  box-shadow: none;
}


.btn.btn-outline-primary:hover,
.btn.btn-primary:hover {
  background-color: #403D39;
  color: #fff;
}


.btn.btn-outline-primary:active,
.btn.btn-outline-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary:focus {
  outline: none;
  box-shadow: none;
}

button.btn.btn-primary {
  border-radius: 8px;
  background: #403D39;
  box-shadow: 0px 4px 4px 0px rgba(151, 151, 151, 0.50);
  color: #FAFAFA;
  padding: 17px 32px;
}

.packages {
  gap: 24px;
  white-space: nowrap;
}

.packages .packages_card {
  flex: 0 0 auto;
  width: 50%;
  max-width: 50%;
}

.packages {
  grid-gap: 24px;
  margin-right: -195px;
  gap: 24px;
  white-space: nowrap;
  z-index: 999;
  position: relative;
}


/*==== login page design ====*/
.new_login {
  background-color: #fff;
  min-height: 100vh;
  width: 100%;
  top: 0%;
  padding-left: 0px;
  padding-right: 0px;
  max-height: 100vh;
  z-index: 9991;
}

.login_dark {
  width: 100%;
  height: 100vh;
  background: #403D39;
}

.modal_close_button .back-btn {
  top: 0;
  left: 0;
  width: auto;
  position: relative;
  padding: 0 !important;
}

.modal_close_button {
  position: absolute;
  left: 32px;
  top: 24px;
}

.login-header.login_outer {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.6px;
  color: #403D39;
  margin-bottom: 40px;
  text-align: center;
  position: absolute;
  top: 24px;
}

.login .login_form form .input-div {
  background-color: #f8f8f8;
  border-radius: 4px 4px 0px 0px;
  padding-left: 10px;
  margin-bottom: 35px;
  position: relative;
  border-bottom: 2px solid #403D39;
}

.login_error .invalid-feedback div {
  bottom: -25px;
  right: 0;
}

.paswword_login.password_icon_login {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}

.login .login_form .remember {
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  color: #CCC5B9;
}

.login .login_form .on-off-dot {
  border: 2px solid #CCC5B9;
}

.login .login_form .forgot {
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  color: #403D39;
  cursor: pointer;
}

.login .login_form p.register {
  color: #999898;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.login .login_form p.sign-upp.sign-up-toggle {
  color: #403D39;
  font-size: 16px;
  margin: 0;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
}

/*=== sign up page ===*/
.sign_up_page_title h2 {
  color: #403D39;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -2px;
  margin: 0;
}

.sign_up_input form .input-div {
  background: transparent;
  border: none;
  height: auto;
  padding: 0;
  position: relative;
  margin: 0;
}

.sign_up_input form .input-div input {
  border-radius: 8px;
  border: 1px solid #403D39;
  padding: 12px 20px;
  color: #999898;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.18px;
}

.sign_up_input form p.pass-disc {
  color: #403D39;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.sign_up_input form .input-div input::placeholder {
  color: #999898;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.18px;
}

.sign_up_input form .input-div label {
  color: #4E4E4E;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background: #FFF;
  position: absolute;
  padding: 0px 4px;
  left: 15px;
  top: -11px;
}

/*=== forgot pwd ===*/
.forgot_detail h2 {
  color: #403D39;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -2.4px;
}

.forgot_detail h2 span {
  font-style: italic;
}

.forgot_detail p {
  color: #403D39;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -1.2px;
}

.forgot_form form div input {
  border-radius: 4px;
  border: 1px solid #403D39;
  padding: 13px 20px;
  color: #403D39;


  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

button.btn.btn-primary.forgot_pwd {
  border-radius: 8px;
  background: #EB5E28;
  width: auto;
  border-color: #EB5E28;
  width: 100%;
  max-width: 132px;
}

.invalid-feedback div {
  color: #403D39;
  font-size: 16px;
  font-weight: 400;
}

/*=== checkout ===*/
.checkouut {
  padding: 54px 0;
  position: relative;
}

.payment_details .input input,
.payment_details .input select,
.order_summary input {
  height: 30px;
  border-bottom: 2px solid #CCC5B9;
  color: #403D39;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  transition: 0.5s;
  line-height: 24px;
  padding: 2px 0 8px;
  width: 100%;
}

.order_summary input {
  width: auto;
}

.payment_details .input select {
  border-top: 0;
  border-left: 0;
  outline: none;
  box-shadow: none;
  border-right: 0;
  height: auto;
  color: #CCC5B9;
}

.payment_details .input input::placeholder {
  color: #CCC5B9;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.payment_details .input label {
  color: #CCC5B9;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.payment_details .input input:focus,
.payment_details .input input:hover,
.payment_details .input input:active,
.payment_details .input select:focus,
.payment_details .input select:hover,
.payment_details .input select:active {
  border-bottom: 2px solid #EB5E28 !important;
  transition: 0.5s;
}

.card_info_input .card_col {
  flex: 0 0 auto;
  width: 47.5%;
  max-width: 47.5%;
}

.card_info_input .card_number .input {
  width: 45.2%;
  overflow: hidden;
  flex: 0 0 auto;
}

.remember.save_card {
  color: #403D39;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.payment_details h2 {
  color: #403D39;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -1.6px;
}

.order_summary ul li {
  color: #403D39;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.order_summary ul li span {
  flex: 0 0 auto;
  width: 50%;
  max-width: 50%;
}

.order_summary ul li:not(:last-child) {
  margin-bottom: 32px;
}

.order_summary hr {
  background: #CCC5B9;
  opacity: 1;
}

.order_summary ul li span b {
  font-weight: 700;
}

.checkout_disclaimer ul {
  padding-left: 17px;
  list-style-type: disc;
}

.checkout_disclaimer ul li {
  color: #403D39;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.icon_container input {
  padding-left: 40px !important;
}

.card-icon {
  position: absolute;
  left: 0;
  bottom: 8px;
}

.invalid-feedback div {
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  bottom: -7px;
  color: red;
  background: #fff;
  right: 15px;
  padding: 0px 4px;
}

/*=== order detail ===*/
.order_detail h2 {
  color: #403D39;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -2.8px;
}

.order_detail p {
  color: #403D39;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.order_detail h4 {
  color: #403D39;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.order_detail h6 {
  color: #403D39;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.subscription_summary ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.subscription_summary ul li {
  color: #403D39;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
}

.subscription_summary ul li b {
  font-weight: 700;
}

.subscription_summary ul li:not(:last-child) {
  margin-bottom: 12px;
}

.subscription_summary ul li span:first-child {
  flex: 0 0 auto;
  width: 40%;
  max-width: 40%;
}

.order_detail_sec {
  /* padding: 5vh 0 0px; */
  overflow: hidden;
}

.order_detail_outer {
  padding: 68px 0;
}

.detail_img {
  height: 100vh;
  background: #252422;
}

.detail_img.detail_plan_img {
  background: #fff;
}

.detail_img.detail_plan_img img {
  width: 94%;
  margin-left: -10vw;
  top: -5vh;
}

.detail_img.detail_plan_img:after {
  display: none;
}

.detail_img.detail_plan_img:before {
  background-image: url(assets/images/new-home/suscription-bg.png);
  background-size: 460px;
  bottom: -100px;
}

.detail_img:after {
  position: absolute;
  content: "";
  background-image: url(assets/images/new-home/left_vector.png);
  width: 380px;
  height: 380px;
  bottom: 0;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom left;
}

.detail_img:before {
  position: absolute;
  content: "";
  background-image: url(assets/images/new-home/right_vector.png);
  width: 670px;
  height: 670px;
  bottom: 0;
  right: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.detail_img img {
  margin-left: -20vw;
  width: 140%;
  position: relative;
  top: 0px;
}

/*=== contact us ====*/
.contact-us.new_contact {
  background-color: #fff;
  min-height: 100vh;
  width: 100%;
  top: 0%;
  right: 0;
  z-index: 9991;
  padding: 0px 0px;
  max-height: 100vh;
}

.contact_header h2 {
  color: #403D39;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: -2.4px;
}

.contact_info li {
  color: #403D39;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -1.2px;
  display: flex;
  align-items: center;
  gap: 24px;
}

.contact_info ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.contact-form input,
textarea {
  border: 2px solid #403D39;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  padding: 14px 16px;
  margin-bottom: 16px;
  color: #403D39 !important;
}

.invalid-feedback {
  margin-top: 0 !important;
}

.login_error .contact-form .invalid-feedback div {
  bottom: 0;
  right: 0;
  background: transparent;
  font-size: 11px;
  font-weight: 400;
  padding: 0px 0px;
}

.login_error .contact-form .invalid-feedback.text-area div {
  bottom: -10px;
}

.contact-form input::placeholder,
textarea::placeholder {
  color: #CCC5B9;
}

.contact_info li svg {
  flex: 0 0 auto;
  width: 24px;
}

.contact-form textarea {
  margin: 0;
}

/*== profile page ===*/
.profile_page ul {
  border: none;
}

.profile_page ul li button {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 !important;
  border: none !important;
  color: #CCC5B9;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.profile_page ul li button svg path {
  stroke: #CCC5B9;
}

.profile_page ul li button.active svg path,
.profile_page ul li button:hover svg path {
  stroke: #fff;
}

.profile_page ul li button.active,
.profile_page ul li button:hover {
  color: #403D39;
  text-decoration-line: underline;
}

.profile_page ul li button .icon_box {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
}

.profile_page ul li button.active .icon_box,
.profile_page ul li button:hover .icon_box {
  background-color: #EB5E28;
}

.profile_page ul li:not(:last-child) {
  margin-bottom: 16px;
}

.active_rout {
  border-radius: 22px;
  background: #403D39;
  padding: 4px 12px 4px 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #FAFAFA;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.active_status h2 {
  color: #403D39;
  font-size: 24px;
  font-style: normal;
  margin: 0;
  font-weight: 700;
  line-height: 32px;
}

.active_status h2 {
  flex: 0 0 auto;
  width: 100%;
  max-width: 222px;
  display: inline-block;
}

.monthly_plan ul {
  list-style-type: none;
}

.monthly_plan ul li {
  color: #403D39;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.8px;
}

.monthly_plan ul li b {
  font-weight: 700;
}

.monthly_plan ul li span:first-child {
  flex: 0 0 auto;
  width: 100%;
  max-width: 222px;
}

.profile_table table tr:first-child td {
  padding-top: 0;
}

.profile_table table tr td {
  color: #403D39;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  vertical-align: middle;
  line-height: 24px;
  padding: 12px 12px 16px 12px;
}

.profile_table .table>:not(:first-child) {
  border-top: 0px solid currentColor;
}

.padi_status {
  border-radius: 8px;
  background: #92DC48 !important;
  color: #403D39;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  width: 87px;
  padding: 4px 8px;
}

.download {
  color: #403D39;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 0 8px;
}

.profile_table table tr td:first-child {
  padding-left: 0;
}

.profile_page {
  padding: 80px 0;
}

.profile_table table tr td {
  border-bottom: 1px solid #CCC5B9;
}

.active_status p {
  color: #403D39;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.payment_detail select,
.payment_detail input {
  border-radius: 8px;
  border: 1px solid #000;
  padding: 10px;
  color: #403D39;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
}

.payment_detail input::placeholder {
  color: #403D39;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.payment_detail label {
  width: 100%;
  max-width: 112px;
  flex: 0 0 auto;
}

.profile_table table tr:last-child td {
  border-bottom: none;
}

.change_password label {
  max-width: 223px;
}

.form.pwd_ins p {
  color: #403D39;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.eye_icon {
  position: absolute;
  right: 8px;
  top: 8px;
}

form.payment_detail.change_password input {
  height: 56px;
  padding: 10px 16px;
}

form.payment_detail.change_password input::placeholder {
  font-style: italic;
}

.reset_pwd_option h2 {
  color: #403D39;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1.6px;
}

.reset_pwd_option p,
.reset_pwd_option ul li {
  color: #403D39;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.8px;
}

.reset_pwd_option ul {
  list-style-type: none;
  position: relative;
  left: -45px;
}

.reset_pwd_option ul li svg {
  position: absolute;
  left: 0;
}

section.reset-password {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
}

.reset_pwd_option ul li {
  padding-left: 45px;
  position: relative;
}


/*=== reset successfully ===*/
.resertSuccessFull {
  padding: 80px 0 150px;
}

.reset_form .forgot {
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  color: #403D39;
  cursor: pointer;
}

.reset_form .remember {
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  color: #403D39;
}

.reset_form .on-off-btn,
.reset_form .on-off-dot {
  border: 2px solid #403D39;
}

.reset_form .login-box-pc ::placeholder,
.reset_form input {
  width: 100%;
  background-color: transparent;
}

.reset_form {
  border-radius: 32px;
  background: #FFF;
  box-shadow: -32px 32px 64px 0px #999898;
  padding: 48px 32px 32px;
}

.invalid-feedback.pswword_validation div {
  bottom: auto;
  top: 46%;
}

/*=== media ===*/
@media (max-width:1400px) {
  .real_time {
    margin-top: -300px;
  }
}

@media (max-width:1300px) {
  .real_time {
    margin-top: -250px;
  }

  .login-header.login_outer {
    font-size: 29px;
    position: relative;
    top: 0;
    width: 100%;
    white-space: nowrap;
  }

  .sign_up_page_title h2 {
    font-size: 30px;
  }
}

@media (max-width:1200px) {
  .real_time {
    margin-top: -170px;
  }
}

@media (max-width:960px) {
  .navbar-light .navbar-toggler {
    background: #fff;
    outline: none !important;
    box-shadow: none !important;
  }

  .banner_section {
    height: 50vh;
  }

  .fast_accurte {
    background-size: 80%;
    padding: 40px 0;
  }

  .section_content h2 br,
  .section_content p br,
  .banner_content h1 br,
  .banner_content p br {
    display: none !important;
  }

  .real_time {
    margin-top: 20px;
  }

  .real_time {
    background-position: 100% 54%;
  }

  section.ensure {
    background-size: 83%;
    background-position: 0% 5%;
    padding: 80px 0;
    margin-top: 40px;
  }

  .packages {
    grid-gap: 18px;
    margin-right: 0;
    grid-gap: 18px;
    gap: 18px;
    white-space: pre-wrap;
    z-index: 999;
    position: relative;
  }

  .packages .packages_card {
    flex: 0 0 auto;
    width: 49%;
    max-width: 49%;
  }
}

@media (max-width:767px) {
  .banner_content h1 {
    font-size: 35px;
    line-height: 45px;
  }

  .banner_content p {
    font-size: 16px;
    line-height: 25px;
  }

  .banner_section {
    height: 40vh;
  }

  .section_content h2 {
    font-size: 30px;
    line-height: 35px;
  }

  .section_content p {
    font-size: 14px;
    line-height: 22px;
  }

  .real_time {
    background-position: 100% 35%;
    background-size: 100%;
  }

  section.ensure {
    background-size: 80%;
    background-position: 0% 5%;
    padding: 40px 0;
    margin-top: 40px;
  }

  .packages .packages_card {
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
  }

  .packages {
    flex-direction: column;
  }

  section.find_the_right {
    padding-bottom: 40px;
    position: relative;
    overflow: hidden;
  }

  .packages_card {
    border-radius: 20px;
    padding: 15px;
  }

  .packages_card_detail h4 {
    font-size: 25px;
    line-height: 35px;
    letter-spacing: -1px;
  }

  .packages_card_detail p {
    font-size: 14px;
    line-height: 20px;
  }

  .packages_card_detail ul li {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -1px;
  }

  .btn.btn-outline-primary {
    font-size: 15px;
    line-height: 24px;
    padding: 10px 10px;
  }

  .footer-btn-container {
    padding: 0 4%;
  }

  .footer-btn-container .contact-btn {
    margin: 0 !important;
  }

  .footer-btn-container .contact-btn:not(:last-child) {
    margin-bottom: 15px !important;
  }

  footer {
    padding: 50px 0 70px;
  }

  .order_summary ul li {
    font-size: 13px;
  }

  .order_summary ul li:not(:last-child) {
    margin-bottom: 12px;
  }

  .payment_details h2 {
    font-size: 30px;
  }

  .payment_details .input input,
  .payment_details .input select,
  .payment_details .input input::placeholder {
    font-size: 15px;
  }

  .payment_details .input label {
    font-size: 14px;
  }

  button.btn.btn-primary {
    padding: 10px 11px;
    font-size: 15px;
    font-weight: 600;
  }

  .checkout_disclaimer ul li {
    font-size: 14px;
  }

  .card_info_input .card_col {
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  .card_info_input .card_number .input {
    width: 50%;
    overflow: hidden;
    flex: 0 0 auto;
  }

  .login-header.login_outer {
    font-size: 25px;
    position: relative;
    top: 0;
  }

  .modal_close_button {
    position: absolute;
    left: 0;
    top: 0;
  }

  .modal_close_button button {
    margin: 0 !important;
  }

  .sign_up_page_title h2 {
    font-size: 30px;
    line-height: 45px;
  }

  .forgot_detail h2 {
    font-size: 30px;
    line-height: 46px;
  }

  .forgot_detail p {
    font-size: 16px;
    line-height: 27px;
    letter-spacing: -1.2px;
  }

  .detail_img img {
    margin-left: -20vw;
    width: 100%;
    position: relative;
    top: 0px;
  }

  .detail_img {
    height: 50vh;
    background: #252422;
  }

  .detail_img:before,
  .detail_img:after {
    display: none;
  }

  .order_detail h2 {
    font-size: 30px;
    line-height: 44px;
  }

  .order_detail p {
    font-size: 15px;
    line-height: 25px;
  }

  .order_detail h4 {
    font-size: 20px;
  }

  .subscription_summary ul li {
    font-size: 14px;
  }

  .reset_form {
    border-radius: 20px;
    padding: 25px 25px 25px;
  }

  .resertSuccessFull {
    padding: 60px 0 60px;
  }
}


